<template>
	<section>
		<BaseText>
			<h1>Dinos &amp; Co</h1>
			<h2>Wer lebte mit wem?</h2>

			<p>
				Für alle die sich schon immer gefragt haben, welcher Dinosaurier gleichzeitig mit anderen zusammenlebte?
			</p>
		</BaseText>
		<FilterBase type="menu" />
		<TimelineBase />
	</section>
</template>

<script>
export default {
	components: {
		FilterBase: () => import(/* webpackChunkName: "FilterBase" */ '@/components/filter/FilterBase'),
		TimelineBase: () => import(/* webpackChunkName: "TimelineBase" */ '@/components/timeline/TimelineBase')
	}
};
</script>
